<template>
  <div class="page">
    <div class="infoBox">
      <div class="img"><img :src="detail.image" alt="" /></div>
      <div class="name">{{ detail.lotteryName }}</div>
      <div class="time">{{ detail.endTime }}自动开奖</div>
    </div>
    <div class="prizeBox" v-if="detail.awardInfoList">
      <div class="prize-item" v-for="(t, i) in detail.awardInfoList" :key="i">
        {{ t.awardGrade }}：{{ t.awardName }} <span>X{{ t.awardCount }}份</span>
      </div>
    </div>
    <div class="optionBox">
      <img
        :src="btnFormat(detail.status)"
        class="statusBtn"
        @click="btnClick()"
      />
      <div class="message" v-if="detail.avatars">
        已有{{ detail.avatars.length }}个人参与
      </div>
      <div class="joinMenList" v-if="detail.avatars">
        <div
          class="joinMenItem"
          v-for="(item, index) in detail.avatars.slice(0, 7)"
          :key="index"
        >
          <img
            :src="item ? item : require('@/assets/img/default_avatar.png')"
            alt=""
          />
        </div>
      </div>
      <div
        class="winnerList"
        v-if="detail.status === 6 || detail.status === 4 || detail.status === 5"
      >
        <div class="title" v-if="detail.getAwardList2.length > 0">中奖晒单</div>
        <div v-if="detail.getAwardList2.length > 0">
          <div
            class="winner-item"
            v-for="(item, index) in detail.getAwardList2"
            :key="index"
          >
            <div class="winner-item-top">
              {{ item[0].awardGrade }}：{{ item[0].awardName }}，{{
                item.length
              }}人中奖
            </div>
            <div class="winnerInfoList">
              <div
                class="winnerInfoItem"
                v-for="(item2, index2) in item"
                :key="index2"
              >
                <div class="img">
                  <img
                    :src="
                      item2.avatar
                        ? item2.avatar
                        : require('@/assets/img/default_avatar.png')
                    "
                    alt=""
                  />
                </div>
                <div class="name">{{ item2.nickName }}</div>
                <div class="phoneNum">{{ item2.mobile }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lotteryDescription">
      <div class="title">抽奖说明</div>
      <div v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import { stringify } from "querystring";
import { mapState } from "vuex";
import {
  applyLotteryUrl,
  getLotteryDetailForMiniUrl,
  userInfoUrl,
} from "./api.js";
export default {
  data() {
    return {
      id: null,
      detail: {},
      userInfo: {},
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
  },
  async mounted() {
    this.getDetail();
    if (this.userId === "0") {
    } else {
      await this.getuserInfo();
    }
  },
  methods: {
    async getuserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(`${userInfoUrl}`, { params: params });
      if (res.code === 200) {
        this.userInfo = res.data;
      }
    },
    getDetail() {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      this.$axios
        .get(`${getLotteryDetailForMiniUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.detail = res.data;
            this.detail.image = this.$handleImg(690, 330, this.detail.image);
            if (this.detail.getAwardList.length > 0) {
              this.detail.getAwardList2 = this.sortArr(
                this.detail.getAwardList,
                "awardGrade"
              );
            } else {
              this.detail.getAwardList2 = [];
            }
          }
        });
    },
    sortArr(arr, str) {
      //数组格式化
      let _arr = [],
        _t = [],
        // 临时变量
        _tmp;

      // 有相同值的排在一起
      arr = arr.sort(function (a, b) {
        let s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });
      console.log(arr);
      if (arr.length) {
        _tmp = arr[0][str];
      }
      console.log(arr);
      // 将有相同类别的对象添加到统一个数组
      for (let i in arr) {
        if (arr[i][str] === _tmp) {
          _t.push(arr[i]);
        } else {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      _arr.push(_t);
      console.log(_arr); //格式化后数组
      return _arr;
    },
    async btnClick() {
      if (this.detail.status == 2) {
        if (await toRegister(this.$route.path, this.$route.query, "幸运抽奖")) {
          let params = {
            lotteryId: this.id,
            userId: this.userId,
            houseId: this.userInfo.chosenSpaceId,
            houseName: this.userInfo.chosenSpacePath,
            mobileNum: this.userInfo.mobile,
            sex: this.userInfo.sex,
          };
          this.$axios.post(`${applyLotteryUrl}`, params).then((res) => {
            if (res.code == 200) {
              this.getDetail();
            }
          });
        }
      }
    },
    btnFormat(status) {
      let x = null;
      switch (status) {
        case 3:
          x = require("./img/dkj.png");
          break;
        case 4:
          x = require("./img/yjs.png");
          break;
        case 99:
          x = require("./img/yxj.png");
          break;
        case 5:
          x = require("./img/xxcy.png");
          break;
        case 6:
          x = require("./img/zjl.png");
          break;
        case 2:
          x = require("./img/ljcy.png");
          break;
      }
      return x;
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 0 68px 0;
  background: #f9f9f9;
  .lotteryDescription {
    padding: 46px 28px;
    background: #ffffff;
    box-sizing: border-box;
    font-size: 28px;
    color: #333333;
    /deep/ video {
      width: 100%;
    }
    /deep/ img {
      width: 100%;
    }
    .title {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      padding-bottom: 50px;
    }
  }
  .optionBox {
    padding: 48px 0 70px;
    background: #ffffff;
    box-sizing: border-box;
    margin-bottom: 20px;
    .winnerList {
      margin-top: 68px;
      padding: 0 30px;
      box-sizing: border-box;
      .winner-item:nth-last-child(1) {
        margin-bottom: 0;
      }
      .winner-item {
        width: 100%;
        background: #ffffff;
        border-radius: 4px;
        border: 2px solid #eaeaea;
        margin-bottom: 50px;
        .winnerInfoList {
          padding: 38px 36px;
          box-sizing: border-box;
          .winnerInfoItem {
            display: flex;
            align-items: center;
            margin-bottom: 34px;
            .phoneNum {
              flex: 1;
              font-size: 26px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
            }
            .name {
              padding-left: 24px;
              box-sizing: border-box;
              flex: 1;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
            }
            .img {
              width: 88px;
              height: 88px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .winnerInfoItem:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
        .winner-item-top {
          height: 82px;
          background: #f7f7f7;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
          line-height: 82px;
        }
      }
      .title {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        padding-bottom: 32px;
      }
    }
    .joinMenList {
      height: 60px;
      display: flex;
      justify-content: center;
      .joinMenItem {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin-right: 12px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .joinMenItem:nth-last-child(1) {
        margin-right: 0;
      }
    }
    .message {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      margin-bottom: 28px;
    }
    .statusBtn {
      width: 220px;
      margin: 0 auto;
      display: block;
      margin-bottom: 34px;
    }
  }
  .prizeBox {
    padding: 42px 32px;
    background: #ffffff;
    box-sizing: border-box;
    margin-bottom: 20px;
    .prize-item:nth-last-child(1) {
      margin-bottom: 0;
    }
    .prize-item {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 28px;
      span {
        color: #b4b4b4;
      }
    }
  }
  .infoBox {
    padding: 0 30px 38px;
    box-sizing: border-box;
    background: #ffffff;
    margin-bottom: 20px;
    .time {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
    .name {
      margin-bottom: 10px;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
    }
    .img {
      width: 100%;
      height: 330px;
      margin-bottom: 34px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>